import { LoadingIndicator } from 'imdui';

export const LongLoader = ({
  size,
  color = 'var(--accent)',
}: {
  color?: string;
  size?: 'small' | 'xLarge';
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <LoadingIndicator color={color} size={size} animationOffset={4} />
      <LoadingIndicator color={color} size={size} animationOffset={10} />
      <LoadingIndicator color={color} size={size} animationOffset={7} />
    </div>
  );
};
