import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerSalesCommission } from 'imddata';
import styled from '@emotion/styled';
import { BodyM } from '@imus/services-ui/src/Text';
import { BaseGrid, Separtor } from '@imus/base-ui';
import { SubscribeUpsell } from 'components/atoms';

const CommissionText = styled.div`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));

  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 56px */
  letter-spacing: -2.24px;
`;

const CommissionWrapper = styled.div`
  text-align: center;
  display: grid;
  gap: 12px;
`;

export const InfoValueDisplay = ({
  value,
  subValue,
}: {
  value: string;
  subValue?: React.ReactNode;
}) => {
  return (
    <CommissionWrapper>
      <CommissionText>{value}</CommissionText>
      {subValue && <BodyM>{subValue}</BodyM>}
    </CommissionWrapper>
  );
};

export const CustomerCommisionRate = ({
  onClickSubscribe,
  mode = 'subscribe',
}: {
  onClickSubscribe: React.MouseEventHandler;
  mode?: 'subscribe' | 'upgrade';
}) => {
  const { t } = useTranslation();

  const salesCommission = useCustomerSalesCommission();
  return (
    <BaseGrid>
      <InfoValueDisplay
        value={`${salesCommission}%`}
        subValue={t('sales-commission')}
      />
      {Number(salesCommission || '0') > 0 && (
        <>
          <Separtor />
          <SubscribeUpsell
            title={t('commission-rate-upsell', { context: mode })}
            description={t('commission-rate-upsell-description', {
              context: mode,
            })}
            actionText={t('upgrade')}
            onClickSubscribe={(e) => onClickSubscribe(e)}
          />
        </>
      )}
    </BaseGrid>
  );
};
