import styled from '@emotion/styled';
import ArtistHubIcon from './artist-hub.svg';
import InstantMasterinProductIcon from './mastring.svg';
import MusicDistributionProductIcon from './dmd.svg';
import { BodySmall, Card, H4, LinkButton } from 'imdui';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ChevronRightIcon } from '@imus/base-ui';

const ProductButton = styled(LinkButton)`
  margin-top: 24px;
  flex: 1;
  display: none;

  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
  }
`;
const Chevron = styled(ChevronRightIcon)`
  display: flex;
  --on-surface: var(--accent);
  @media (min-width: 1024px) {
    display: none;
  }
`;

const ProductCardContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: auto;
  transition: all 0.2s ease-in-out;

  ${BodySmall} {
    color: var(--on-surface-var, #353534);
    font-feature-settings: 'ss01' on;
  }
  div {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }
`;
const ProductCard = styled(Card)`
  display: flex;
  cursor: pointer;
  gap: 16px;
  padding: 24px;
  align-items: center;

  @media (min-width: 1024px) {
    align-items: start;
    padding: 32px;
    flex-direction: column;
    gap: 24px;
    border-radius: 40px;
  }

  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  background: var(--surface-container-low, #edeae8);

  /* Shadow/1 */
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 4px 0px rgba(0, 0, 0, 0.05);

  svg {
    transition: all 0.2s ease-in-out;
    width: 32px;
    height: 32px;
  }
  &:hover {
    border: 1px solid var(--accent, #004e64);
    background: var(--surface-container-low, #e9e7e5);

    /* Shadow/2 */
    box-shadow:
      0px 0px 8px 0px rgba(0, 0, 0, 0.05),
      0px 1px 3px 0px rgba(0, 0, 0, 0.08);

    ${ProductButton} {
      --on-surface: var(--on-accent);

      --state-active-on-surface: var(--accent, #004e64);
    }
  }
`;

const ProductTitle = styled(H4)`
  @media (min-width: 1024px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
`;

export const DashboardProductRow = () => {
  const histpry = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <ProductCard
        secondary
        onClick={() => {
          histpry.push('/order/md/new');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'music-distribution',
            });
          }
        }}
      >
        <MusicDistributionProductIcon />

        <ProductCardContent>
          <div>
            <ProductTitle>{t('distribute-music')}</ProductTitle>
            <BodySmall secondary>
              {t('music-distribution-onboarding-text')}
            </BodySmall>
          </div>
          <ProductButton
            testId="start-distributing"
            to="/order/md/new"
            appearance="fill"
            text={t('explore')}
          />
        </ProductCardContent>
        <Chevron />
      </ProductCard>
      <ProductCard
        secondary
        onClick={() => {
          histpry.push('/order/instant-mastering');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'mastering',
            });
          }
        }}
      >
        <InstantMasterinProductIcon />
        <ProductCardContent>
          <div>
            <ProductTitle>{t('instant-mastering')}</ProductTitle>
            <BodySmall secondary>
              {t('instant-mastering-dashboard-text')}
            </BodySmall>
          </div>
          <ProductButton
            to="/order/instant-mastering"
            appearance="fill"
            text={t('master-my-track')}
          />
        </ProductCardContent>
        <Chevron />
      </ProductCard>
      <ProductCard
        secondary
        onClick={() => {
          histpry.push('/products/artist-hub');
          if (window?.analytics) {
            window.analytics.track('FT Product Dashboard Click', {
              product: 'release-page',
            });
          }
        }}
      >
        <ArtistHubIcon />
        <ProductCardContent>
          <div>
            <ProductTitle>{t('brand-and-release-promo')}</ProductTitle>
            <BodySmall secondary>{t('artist-hub-dashboard-text')}</BodySmall>
          </div>
          <ProductButton
            size="medium"
            to="/products/artist-hub"
            appearance="fill"
            text={t('explore-artist-hub')}
          />
        </ProductCardContent>
        <Chevron />
      </ProductCard>
    </>
  );
};
