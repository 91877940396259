import React from 'react';
import moment from 'moment';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import {
  Button,
  Block,
  palette,
  Table,
  Content,
  Icons,
  DownloadPayoutReceiptButton,
} from 'imdshared';

import type { EntityModels, NestDefinition } from 'imddata';
import { useEntries, useUpdateEntity } from 'imddata';

const CANCELLED = 9;

const cardStyle = css`
  border-top: 1px solid var(--fg-4);
  padding: 24px;
  overflow: auto;
`;

const PayoutAddress = ({
  address,
}: {
  address?: EntityModels.PayoutAddress;
}) => (
  <Link to={`/account/billing/${address?.id}`}>
    {address?.name || address?.payoutMethod?.name}
  </Link>
);

const CancelPayoutRequest = ({
  id,
  status,
}: {
  id: number;
  status?: string;
}) => {
  const {
    updateEntry,
    request: { loading: isCancelling },
  } = useUpdateEntity({ entity: 'payouts', id });

  return status === 'requested' ? (
    <Button
      disabled={isCancelling}
      showLoading={isCancelling}
      text="Cancel"
      icon={Icons.actions.close}
      style={{ borderRadius: 0 }}
      onClick={(event) => {
        event.stopPropagation();
        updateEntry({
          data: {
            status: CANCELLED,
          },
        });
      }}
    />
  ) : null;
};

type Payout = EntityModels.Payout<EntityModels.PayoutAddress>;

const AddressRenderer = ({ rowData }: { rowData: Payout }) => (
  <PayoutAddress address={rowData.payoutAddress} />
);

const StatusRenderer = ({ cellData }: { cellData: string }) => (
  <Block padding={[10, 0, 10, 0]}>
    <Content style={{ color: palette.main.normal }}>{cellData}</Content>
  </Block>
);

const DownloadIconRenderer = ({ cellData }: { cellData: number }) => (
  <DownloadPayoutReceiptButton id={cellData} type="icon" text={undefined} />
);

const fields = [
  {
    dataKey: 'createdAt',
    label: 'created',
    cellRenderer: ({ cellData }: { cellData: string }) =>
      moment(cellData).format('LL'),
  },
  {
    dataKey: 'payoutAddressId',
    label: 'address',
    cellRenderer: AddressRenderer,
  },
  {
    dataKey: 'grossAmountFormatted',
    label: 'gross-amount',
  },
  {
    dataKey: 'netAmountFormatted',
    label: 'net-amount',
  },
  {
    dataKey: 'statusText',
    label: 'status',
    cellRenderer: StatusRenderer,
  },
  {
    dataKey: 'id',
    label: 'action',
    cellRenderer: function CancelPayoutRequestAction({
      rowData: payout,
    }: {
      rowData: Payout;
    }) {
      return <CancelPayoutRequest id={payout?.id} status={payout?.status} />;
    },
  },
  {
    dataKey: 'id',
    label: 'download',
    cellRenderer: DownloadIconRenderer,
  },
];

const nestAddresses: NestDefinition = [
  {
    key: 'payoutAddress',
    getKey: (entry?: EntityModels.Payout) => entry?.payoutAddressId,
    entity: 'payoutAddresses',
    collect: true,
  },
];

const RecentPayouts: React.FC = () => {
  const {
    entries,
    loadMore,
    request: { loading, hasMorePages },
  } = useEntries<Payout>({
    nest: nestAddresses,
    entity: 'payouts',
  });

  return (
    <Table
      css={cardStyle}
      // @ts-ignore
      fields={fields}
      loadNextPage={loadMore}
      hasNextPage={hasMorePages}
      // @ts-ignore
      items={entries}
      loading={loading}
    />
  );
};

export default RecentPayouts;
