import { useMemo } from 'react';
import type { Query } from '../../../types';
import { useEntriesProvider } from '../base';
import { useEntry, useIsAdmin, useEntries } from '../hooks';
import type { TrackNested } from './nests';
import { defaultTrackNest } from './nests';

const isTrackInDelivery = (entry: { deliveryStatus: string } | undefined) =>
  entry && entry.deliveryStatus !== 'not_delivered';

const isTrackInContentId = <T extends { deliveryStatus: string }>(
  entry: T | undefined,
  hasDelivery: boolean | undefined
) => !hasDelivery && entry && entry.deliveryStatus !== 'not_delivered';

type EditableFields = {
  customIsrc: boolean;
  audioUpload: boolean;
  names: boolean;
  genreId: boolean;
  copyrightYear: boolean;
  copyrightText: boolean;
  artists: boolean;
  performers: boolean;
  contributors: boolean;
  publishers: boolean;
  isCoverVersion: boolean;
  hasExplicitContent: boolean;
  isLiveVersion: boolean;
  isMedley: boolean;
};
const fields: EditableFields = {
  customIsrc: true,
  audioUpload: true,
  names: true,
  genreId: true,
  copyrightYear: true,
  copyrightText: true,
  artists: true,
  performers: true,
  contributors: true,
  publishers: true,
  isCoverVersion: true,
  hasExplicitContent: true,
  isLiveVersion: true,
  isMedley: true,
};

const useEditableTrackFields = (entries: TrackNested[]): EditableFields => {
  const isAdmin = useIsAdmin();
  return useMemo<EditableFields>(() => {
    if (isAdmin) {
      return fields;
    }
    return entries
      ? entries.reduce((acc, entry) => {
        const hasDelivery = isTrackInDelivery(entry);
        const hasContentId = isTrackInContentId(entry, hasDelivery);

        return {
          audioUpload: acc.audioUpload && !hasDelivery && !hasContentId,
          names: acc.names,
          genreId: acc.genreId && !hasDelivery,
          copyrightYear: acc.copyrightYear && !hasDelivery,
          copyrightText: acc.copyrightText && !hasDelivery,
          customIsrc: acc.customIsrc && !hasDelivery,
          artists: acc.artists && !hasDelivery,
          contributors: acc.contributors && !hasDelivery,
          performers: acc.performers && !hasDelivery,
          publishers: acc.publishers && !hasDelivery,
          isCoverVersion: acc.isCoverVersion && !hasDelivery,
          hasExplicitContent: acc.hasExplicitContent && !hasDelivery,
          isLiveVersion: acc.isLiveVersion && !hasDelivery,
          isMedley: acc.isMedley && !hasDelivery,
        };
      }, fields)
      : Object.keys(fields).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        fields
      );
  }, [entries]);
};

const mapTrackAudio = (track: TrackNested) => {
  const audioPreview = track.audios?.find((trackAudio) => {
    return trackAudio && trackAudio?.preset === 'mp3-preview';
  });

  return {
    ...track,
    audioPreview: audioPreview
      ? {
        ...audioPreview,
        file: {
          ...(audioPreview.file || {}),
          originalFileName: track?.file?.fileName,
        },
      }
      : undefined,
  };
};

export function useTrack({
  id,
  passive,
}: {
  id: number | string;
  passive?: boolean;
}) {
  const data = useEntry<TrackNested>({
    entity: 'tracks',
    nest: defaultTrackNest,
    id,
    passive,
  });

  const { entry } = data;
  const hasDelivery = isTrackInDelivery(entry);
  const hasContentId = isTrackInContentId(entry, hasDelivery);
  const entries = useMemo(() => (entry ? [entry] : []), [entry]);

  const editableFields = useEditableTrackFields(entries);
  const track = useMemo(() => {
    if (!entry) return null;
    return mapTrackAudio(entry);
  }, [entry]);
  return {
    ...data,
    entry: track,
    hasContentId,
    hasDelivery,
    editableFields,
  };
}

export function useTracks({
  passive,
  ids,
  query,
  queryHash,
}: {
  queryHash?: string;
  query?: Query;
  ids?: Array<number | string>;
  passive?: boolean;
} = {}) {
  const { entries, ...data } = useEntries<TrackNested>({
    passive,
    queryHash,
    entity: 'tracks',
    query,
    nest: defaultTrackNest,
    ids,
  });

  const trackWithAudios = useMemo(() => {
    if (!entries) return entries;

    return entries.map(mapTrackAudio);
  }, [entries]);
  return { entries: trackWithAudios, ...data };
}

export function useTracksProvider({ ids }: { ids: Array<number> }) {
  const data = useEntriesProvider<TrackNested>({
    ids,
    entity: 'tracks',
    nest: defaultTrackNest,
  });
  const { entries } = data;

  const editableFields = useEditableTrackFields(entries);

  return {
    ...data,
    editableFields,
  };
}
