import styled from '@emotion/styled';
import { ModalWindowTitle } from '@imus/base-ui';
import { BuyOrSubOffer } from 'imdshared';
import {
  Button,
  Chip,
  FieldCard,
  IconButton,
  OverlineText,
  Radio,
  Window,
} from 'imdui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MasteringTrackAudio } from './MasteringTrackAudio';
import type { EntityModels } from 'imddata';
import { useEntries } from 'imddata';
import { MasteringReferenceAudioUploader } from './MasteringCustomReferenceUploader';

const ButtonGroup = styled.div`
  border-radius: 24px;
  background: var(--on-surface-semi-var, rgba(255, 252, 250, 0.08));
  background: var(
    --on-surface-semi-var,
    color(display-p3 1 0.9882 0.9804 / 0.08)
  );
  display: flex;
  align-items: center;
  align-self: stretch;
  & > * {
    flex: 1;
  }
`;

const Tabs = styled.div`
  margin: 0 32px 32px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 448px;
  overflow-y: auto;
  padding: 32px;
  flex-direction: column;
  gap: 32px;
  border-top: 1px solid var(--outline-var);
  border-bottom: 1px solid var(--outline-var);
`;
const Chips = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 32px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  & > * {
    flex: 1;
  }
`;

const SubscribeUpsellStyled = styled(BuyOrSubOffer)`
  margin-bottom: 0px;
  width: 100%;
`;

const SelectableTrackRow = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const Divider = styled.div`
  background: var(--outline-var, #474746);
  height: 1px;
  width: 100%;
  margin: 24px 0;
`;

export const MasteringStyleSelectorModal = ({
  open,
  onSelect,
  onRequestClose,
}: {
  open: boolean;
  onRequestClose: () => void;
  onSelect: (style: string) => void;
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | null>(null);
  const [mode, setMode] = useState<'genre' | 'reference'>('genre');
  useEffect(() => {
    setSelected(null);
  }, [mode]);
  const { entries: masteringReferenceAudios } =
    useEntries<EntityModels.MasteringReferenceAudio>({
      entity: 'masteringReferenceAudios',
    });

  const genres = masteringReferenceAudios.filter(
    (mfa) => mfa.customerId === null
  );
  const custom = masteringReferenceAudios.filter(
    (mfa) => mfa.customerId !== null
  );
  return (
    <Window
      style={{ maxWidth: '448px' }}
      close={onRequestClose}
      isOpen={open}
      title={
        <>
          <ModalWindowTitle>{t('choose-mastering-style')}</ModalWindowTitle>
        </>
      }
    >
      <Tabs>
        <ButtonGroup>
          <Button
            appearance={mode === 'genre' ? 'elevated' : 'transparent'}
            text={t('use-genre')}
            onClick={() => setMode('genre')}
          ></Button>
          <Button
            appearance={mode === 'reference' ? 'elevated' : 'transparent'}
            text={t('use-reference')}
            onClick={() => setMode('reference')}
          ></Button>
        </ButtonGroup>
      </Tabs>
      <Wrapper>
        {mode === 'genre' && (
          <>
            <SubscribeUpsellStyled
              title={t('instant-mastering-upsell-title')}
              description={t('instant-mastering-upsell-description')}
            ></SubscribeUpsellStyled>
            <Chips>
              {genres.map((g) => {
                return (
                  <Chip
                    size="large"
                    testId={`OnboardingUserCategory-${g.id}`}
                    key={g.id}
                    state={selected === g.id ? 'primary' : undefined}
                    text={t(g.label)}
                    onClick={() => {
                      setSelected(g.id);
                    }}
                  />
                );
              })}
            </Chips>
          </>
        )}
        {mode === 'reference' && (
          <>
            <SubscribeUpsellStyled
              title={t('instant-mastering-reference-upsell-title')}
              description={t('instant-mastering-reference-upsell-description')}
            ></SubscribeUpsellStyled>
            <div>
              <OverlineText label={t('choose-reference-track')} />
              <FieldCard style={{ padding: '24px' }}>
                {custom.map((file) => (
                  <SelectableTrackRow key={file.id}>
                    <IconButton
                      inline
                      onClick={() => {
                        setSelected(file.id);
                      }}
                    >
                      <Radio selected={selected === file.id}></Radio>
                    </IconButton>
                    <MasteringTrackAudio
                      style={{ flex: 1 }}
                      title={file.label}
                    />
                  </SelectableTrackRow>
                ))}
                {custom.length > 0 && <Divider />}
                <MasteringReferenceAudioUploader onChange={console.log} />
              </FieldCard>
            </div>
          </>
        )}
      </Wrapper>
      <Footer>
        <Button
          text={t('cancel')}
          onClick={() => {
            onRequestClose();
          }}
          size="large"
          appearance="elevated"
        />
        <Button
          text={t('generate-preview')}
          disabled={!selected}
          onClick={() => {
            if (selected) {
              onSelect(selected);
            }
          }}
          size="large"
          primary={!!selected}
          appearance="fill"
        />
      </Footer>
    </Window>
  );
};

export const MasteringVersionButtonSelect = ({
  onAddVersion,
}: {
  onAddVersion: (version: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <MasteringStyleSelectorModal
        open={open}
        onRequestClose={() => setOpen(false)}
        onSelect={onAddVersion}
      />
      <Button text={t('add-version')} onClick={() => setOpen(true)} />
    </>
  );
};
