import type React from 'react';
import {
  useMemo,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals } from 'ramda';
import type { ReduxState } from 'redux/reducers';
import { actions } from 'redux/actions/ui/products';
import type {
  ProductStepState,
  UiProductState,
} from 'redux/reducers/ui/products';
import type { RouteComponentProps } from 'react-router';

type StepProps = {
  match: RouteComponentProps['match'];
  stepId: string;
  previousUrl?: string;
  nextUrl?: string;
};

export type ProductState = UiProductState;

export type ProductPageContextType = {
  title: string;
  // TODO: refactor to its own context
  /** @deprecated */
  releaseId?: number | string;

  id: string;
  product:
  | 'release'
  | 'ycid'
  | 'md'
  | 'mastering'
  | 'release-page'
  | 'instant-mastering';
  initialStepsState: ProductStepState[];
  initialData?: Record<string, unknown>;
  entity: 'releases' | 'deliveryBundles' | 'instantMasterings' | 'releasePages';
  overviewEntity:
  | undefined
  | 'deliveryBundleOverview'
  | 'instantMasteringOverview'
  | 'releasePagesOverview';
  steps: ProductStepState[];
  RedirectManager?: React.ComponentType<{
    id: string;
    match: RouteComponentProps['match'];
    defaultFallbackRoute: string;
  }>;
  StepperStateManager?: React.ComponentType<{ currentStepId: string }>;
  StepRenderer: React.ComponentType<StepProps>;
  NavBarRenderer?: React.ComponentType<{
    stepId: string;
    previous?: string | undefined;
    next?: string | undefined;
  }>;
};

export const ProductPageContext = createContext<
  ProductPageContextType | undefined
>(undefined);

ProductPageContext.displayName = 'ProductPageContext';

export const useProductPageActions = () => {
  const stepContext = useContext(ProductPageContext);
  if (!stepContext) {
    throw new Error('Product Context Must be specified');
  }

  const { id, initialStepsState, initialData, product } = stepContext;

  const dispatch = useDispatch();

  const initialize = useCallback(() => {
    dispatch(
      actions.initProductPage({
        data: initialData,
        id,
        product,
        steps: initialStepsState,
      })
    );
  }, [product, id]);

  const changeProduct = useCallback(
    (data: Record<string, unknown>) => {
      dispatch(
        actions.changeProductPage({
          product,
          id,
          data,
        })
      );
    },
    [product, id]
  );

  const changeStep = useCallback(
    (
      {
        stepId,
        payload,
      }: {
        stepId: string;
        payload: Record<string, unknown>;
      },
      meta?: Record<string, unknown>
    ) => {
      dispatch(
        actions.changeProductStep(
          {
            product,
            id,
            stepId,
            payload,
          },
          meta
        )
      );
    },
    [product, id]
  );

  return {
    changeProduct,
    initialize,
    changeStep,
  };
};

export const useProductId = (): string => {
  const stepContext = useContext(ProductPageContext);
  if (!stepContext) {
    throw new Error('Product Context Must be specified');
  }

  return stepContext.id;
};

export const useProductPageContext = (): ProductPageContextType => {
  const stepContext = useContext(ProductPageContext);
  if (!stepContext) {
    throw new Error('Product Context Must be specified');
  }

  return stepContext;
};

const stubState: ProductState = {
  id: '',
  createdAt: 0,
  data: {},
  steps: [],
};

export const useProductPage = (): ProductPageContextType & {
  validSteps: boolean;
  verifyingSteps: boolean;
  state: ProductState;
} => {
  const stepContext = useContext(ProductPageContext);
  if (!stepContext) {
    throw new Error('Product Context Must be specified');
  }

  const { product, id, initialStepsState } = stepContext;

  const { initialize } = useProductPageActions();

  const productState = useSelector((state: ReduxState) => {
    return state.ui.products[product]?.[id];
  });

  const initialState = useMemo<{
    steps: ProductStepState[];
    data: Record<string, unknown>;
  }>(
    () => ({
      data: {},
      steps: initialStepsState.map((data) => ({ ...data, valid: true })),
    }),
    []
  );

  useEffect(() => {
    const isStepsEqual =
      productState?.steps &&
      equals(
        productState.steps.map((step) => step.id),
        initialStepsState.map((step) => step.id)
      );
    if (!productState || !isStepsEqual) {
      initialize();
    }
  }, [!!productState, initialize]);

  const state = productState?.createdAt ? productState : initialState;

  const validSteps = state.steps.reduce(
    (acc, { valid }) => acc && !!valid,
    true
  );
  const verifyingSteps = state.steps.reduce(
    (acc, { verifying, updating }) => acc || !!verifying || !!updating,
    false
  );

  return {
    ...stepContext,
    validSteps,
    verifyingSteps,
    // @ts-ignore
    state: state || stubState,
  };
};
