import styled from '@emotion/styled';
// import { useSettings } from 'imddata';
import type { Action } from 'imdui';
import {
  Button,
  H4,
  Chip,
  H1,
  HelperText,
  NewInput,
  OverlineText,
  FieldCard,
  HelpWindowContext,
  SimpleLayout,
  SimpleLayoutContentWrapper,
} from 'imdui';
import { BodyM } from '@imus/services-ui/src/Text';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import {
  ArtistHubIcon,
  ContentM,
  ContentS,
  MusicMasteringIcon,
  MusicUploadIcon,
  ProgressCheckCircleIcon,
} from '@imus/base-ui';
import { useHistory } from 'react-router';
import { GenreSelectField } from 'imdshared';
import { css } from '@emotion/react';
import { SimpleStepper } from 'components';

type UserCategory = 'artist' | 'label' | 'band' | 'producer' | 'collaborator';

const USER_CATEGORIES: Array<UserCategory> = [
  'artist',
  'label',
  'band',
  'producer',
  'collaborator',
];

const ARTIST_BRACKETS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '5', label: '3 – 5' },
  { value: '15', label: '6 – 15' },
  { value: '16', label: '15+' },
];

enum Step {
  INTRO = 'intro',
  REGULAR = 'regular',
  OUTRO = 'outro',
  LABEL = 'label',
}

const TextBlock = styled.div`
  max-width: 360px;
  width: 100%;
  @media screen and (min-width: 1128px) {
    max-width: auto;
    width: auto;
  }
  ${H1} {
    margin-bottom: 16px;
  }
  ${BodyM} {
    display: inline-flex;
    max-width: 360px;
    color: var(--fg-2);
  }
`;

const FieldBlock = styled.div`
  width: 100%;
  max-width: 360px;
`;

const ContentLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 32px;
`;

const stepToNumber = (step: Step): number => {
  switch (step) {
    case Step.INTRO:
      return 1;
    case Step.REGULAR:
      return 2;
    case Step.OUTRO:
      return 3;
    case Step.LABEL:
      return 2;
  }
};

const OnboardinEndMainOptions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  @media screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

const OnboardingBaseButton = styled.div`
  background: var(--surface-container-low, #fffcfa);
  border-radius: 16px;
  display: grid;
  padding: 24px;
  text-align: center;
  justify-content: center;
  gap: 16px;
  flex: 1 0 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  &:hover {
    box-shadow: 0 0 0 1px var(--accent);
  }
  &:hover::after {
    top: 0;
    left: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
    transition: background-color 0.1s;
    border-radius: inherit;
    background-color: var(--state-hover-accent);
    height: 100%;
    width: 100%;
  }
`;

const ButtonIconWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;

  & > svg {
    --on-surface: var(--accent);
    width: 32px !important;
    height: 32px !important;
  }
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  width: 100%;
  & > * {
  }
`;

const OnboardingMainButton = styled(OnboardingBaseButton)`
  grid-template-rows: 32px 1fr;
  background: var(--surface-container-high, #fffcfa);
  @media screen and (min-width: 640px) {
    aspect-ratio: 1/1;
  }
`;

const NavBlock = styled.div`
  margin-top: auto;
  gap: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1128px) {
    & > * {
      width: 176px;
    }
  }
`;

enum ActionTypes {
  CHANGE = 'CHANGE',
  CONTINUE = 'CONTINUE',
  BACK = 'BACK',
  SKIP = 'SKIP',
}

type Action =
  | {
      type: typeof ActionTypes.CHANGE;
      field:
        | 'userCategory'
        | 'genreId'
        | 'artistName'
        | 'labelName'
        | 'numberOfArtists';
      value: any;
    }
  | { type: typeof ActionTypes.CONTINUE }
  | { type: typeof ActionTypes.BACK }
  | { type: typeof ActionTypes.SKIP };

type State = {
  activeStep: Step;
  values: {
    userCategory: string[];
    genreId: number | undefined;
    artistName?: string;
    labelName?: string;
    numberOfArtists?: string;
  };
  errors: {
    labelName?: string;
    numberOfArtists?: string;
    userCategory?: string;
    genreId?: string;
    artistName?: string;
  };
};

type Reducer = (s: State, a: Action) => State;

const initialState: State = {
  activeStep: Step.INTRO,
  values: {
    userCategory: [],
    genreId: undefined,
  },
  errors: {},
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE: {
      const newErrors = {
        ...state.errors,
        [action.field]: undefined,
      };
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.value,
        },
        errors: newErrors,
      };
    }

    case ActionTypes.BACK: {
      switch (state.activeStep) {
        case Step.INTRO:
          return state;
        case Step.LABEL:
        case Step.REGULAR:
          return {
            ...state,
            activeStep: Step.INTRO,
          };
        case Step.OUTRO:
          return {
            ...state,
            activeStep: state.values.userCategory.includes('label')
              ? Step.LABEL
              : Step.REGULAR,
          };
        default:
          return state;
      }
    }
    case ActionTypes.SKIP: {
      return {
        ...state,
        activeStep: Step.OUTRO,
      };
    }
    case ActionTypes.CONTINUE: {
      switch (state.activeStep) {
        case Step.INTRO:
          if (state.values.userCategory.length)
            return {
              ...state,
              activeStep: state.values.userCategory.includes('label')
                ? Step.LABEL
                : Step.REGULAR,
            };
          else
            return {
              ...state,
              errors: {
                ...state.errors,
                userCategory: 'field-required',
              },
            };
        case Step.LABEL: {
          if (
            state.values.labelName &&
            state.values.genreId &&
            state.values.numberOfArtists &&
            /^\d+$/.exec(state.values.numberOfArtists)
          )
            return {
              ...state,
              activeStep: Step.OUTRO,
            };
          else
            return {
              ...state,
              errors: {
                ...state.errors,
                labelName: !state.values.labelName
                  ? 'field-required'
                  : undefined,
                numberOfArtists: !state.values.numberOfArtists
                  ? 'field-required'
                  : 'must-be-number',
                genreId: !state.values.genreId ? 'field-required' : undefined,
              },
            };
        }
        case Step.REGULAR:
          if (state.values.artistName && state.values.genreId)
            return {
              ...state,
              activeStep: Step.OUTRO,
            };
          else
            return {
              ...state,
              errors: {
                ...state.errors,
                artistName: 'field-required',
                genreId: 'field-required',
              },
            };
        case Step.OUTRO:
          return state;
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

const SimpleLayoutStyled = styled(SimpleLayout)`
  ${SimpleLayoutContentWrapper} {
    max-width: 100%;
  }
`;

export const Onboarding = ({
  onSubmit,
}: {
  onSubmit: (r?: {
    userCategory: string[];
    genreId: number;
    artistName?: string;
    labelName?: string;
    numberOfArtists?: number;
  }) => void;
}) => {
  const [
    {
      activeStep,
      values: { userCategory, numberOfArtists, labelName, genreId, artistName },
      errors,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const history = useHistory();
  const handleSubmit = useCallback(
    (
      trackingEvent: string,
      trackingPayload?: {
        link_clicked: 'dmd' | 'artist hub' | 'mastering' | 'dashboard';
      }
    ) => {
      if (window.analytics) {
        window.analytics.track(trackingEvent, trackingPayload);
      }
      onSubmit({
        userCategory,
        genreId: genreId as number,
        artistName,
        labelName,
        numberOfArtists: numberOfArtists ? Number(numberOfArtists) : undefined,
      });
    },
    [userCategory, artistName, numberOfArtists, labelName, genreId]
  );
  useEffect(() => {
    if (
      activeStep === Step.OUTRO &&
      numberOfArtists &&
      Number(numberOfArtists) >= 5
    ) {
      handleSubmit('FT Clicked ready to distribute onboarding');
      // do not push any route or window will autoclose on route change by
      // default
    }
  }, [activeStep, numberOfArtists]);
  const showHelpWindow = useContext(HelpWindowContext);
  return (
    <SimpleLayoutStyled
      size="wide"
      onClickBack={
        activeStep != Step.INTRO && activeStep != Step.OUTRO
          ? () => dispatch({ type: ActionTypes.BACK })
          : undefined
      }
    >
      <ContentLayout>
        {activeStep === Step.OUTRO ? (
          <ProgressCheckCircleIcon
            css={css(
              `
          width: 48px;
          height: 48px;
          --on-surface: var(--accent);
          `
            )}
          />
        ) : (
          <SimpleStepper activeIndex={stepToNumber(activeStep)} length={3} />
        )}
        <TextBlock>
          <H1>
            {t(`onboarding-title-${activeStep}`, {
              defaultValue: t(`onboarding-title-${stepToNumber(activeStep)}`),
            })}
          </H1>
          <ContentM>
            {t(`onboarding-text-${activeStep}`, {
              defaultValue: t(`onboarding-text-${stepToNumber(activeStep)}`),
            })}
          </ContentM>
        </TextBlock>
        {activeStep === Step.INTRO && (
          <FieldBlock>
            <div>
              <OverlineText
                error={!!errors.userCategory}
                label={t('what-describes-you-best')}
              />
              <FieldCard status={errors.userCategory ? 'error' : undefined}>
                {USER_CATEGORIES.map((key) => {
                  return (
                    <Chip
                      size="large"
                      testId={`OnboardingUserCategory-${key}`}
                      key={key}
                      state={userCategory.includes(key) ? 'primary' : undefined}
                      text={t(key)}
                      onClick={() => {
                        dispatch({
                          type: ActionTypes.CHANGE,
                          field: 'userCategory',
                          value: userCategory.includes(key)
                            ? userCategory.filter((vf) => vf !== key)
                            : [...userCategory, key],
                        });
                      }}
                    />
                  );
                })}
              </FieldCard>
              <HelperText
                errorText={
                  errors.userCategory ? t(errors.userCategory) : undefined
                }
              />
            </div>
          </FieldBlock>
        )}
        {activeStep === Step.REGULAR && (
          <FieldBlock>
            <NewInput
              label={t('what-is-your-artist-name')}
              testId={`artist-name`}
              value={artistName}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-artist-name-title'),
                  t('onboarding-helptext-artist-name-description')
                );
              }}
              errorText={errors.artistName ? t(errors.artistName) : undefined}
              // @ts-ignore
              onChange={(_e, value) => {
                dispatch({
                  type: ActionTypes.CHANGE,
                  field: 'artistName',
                  value,
                });
              }}
            />
            <GenreSelectField
              label={t('what-is-your-main-genre')}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-genre-title'),
                  t('onboarding-helptext-genre-description')
                );
              }}
              testId={`genreId`}
              enableFeatureCheck={false}
              errorText={errors.genreId ? t(errors.genreId) : undefined}
              // @ts-ignore
              input={{
                value: genreId,
                onChange: (value) => {
                  dispatch({
                    type: ActionTypes.CHANGE,
                    field: 'genreId',
                    value,
                  });
                },
              }}
              // @ts-ignore
              meta={{
                touched: true,
                error: errors.genreId,
              }}
            />
          </FieldBlock>
        )}
        {activeStep === Step.LABEL && (
          <FieldBlock>
            <NewInput
              label={t('what-is-your-label-name')}
              testId={`label-name`}
              value={labelName}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-label-name-title'),
                  t('onboarding-helptext-label-name-description')
                );
              }}
              errorText={errors.labelName ? t(errors.labelName) : undefined}
              // @ts-ignore
              onChange={(_e, value) => {
                dispatch({
                  type: ActionTypes.CHANGE,
                  field: 'labelName',
                  value,
                });
              }}
            />

            <div style={{ marginBottom: '16px' }}>
              <OverlineText
                error={!!errors.userCategory}
                label={t('number-of-artists-on-label')}
              />
              <FieldCard status={errors.numberOfArtists ? 'error' : undefined}>
                {ARTIST_BRACKETS.map(({ label, value }) => {
                  return (
                    <Chip
                      size="large"
                      testId={`OnboardingArtistBracket-${label}`}
                      key={label}
                      state={numberOfArtists === value ? 'primary' : undefined}
                      text={label}
                      onClick={() => {
                        dispatch({
                          type: ActionTypes.CHANGE,
                          field: 'numberOfArtists',
                          value: value,
                        });
                      }}
                    />
                  );
                })}
              </FieldCard>

              <HelperText
                errorText={
                  errors.numberOfArtists ? t(errors.numberOfArtists) : undefined
                }
              />
            </div>

            <GenreSelectField
              label={t('what-is-your-main-genre')}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-genre-title'),
                  t('onboarding-helptext-genre-description')
                );
              }}
              testId={`genreId`}
              enableFeatureCheck={false}
              errorText={errors.genreId ? t(errors.genreId) : undefined}
              // @ts-ignore
              input={{
                value: genreId,
                onChange: (value) => {
                  dispatch({
                    type: ActionTypes.CHANGE,
                    field: 'genreId',
                    value,
                  });
                },
              }}
              // @ts-ignore
              meta={{
                touched: true,
                error: errors.genreId,
              }}
            />
          </FieldBlock>
        )}
        {activeStep === Step.OUTRO && (
          <>
            <OnboardinEndMainOptions>
              <OnboardingMainButton
                data-test-id="start-distributing"
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'dmd',
                  });
                  history.push('/order/md/new');
                }}
              >
                <ButtonIconWrap>
                  <MusicUploadIcon />
                </ButtonIconWrap>
                <ButtonText>
                  <H4>{t('upload-your-music', { context: 'onboarding' })}</H4>
                  <ContentS>
                    {t('upload-your-music-description', {
                      defaultValue: 'Release single or album',
                    })}
                  </ContentS>
                </ButtonText>
              </OnboardingMainButton>
              <OnboardingMainButton
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'mastering',
                  });
                  history.push('/order/mastering/new');
                }}
              >
                <ButtonIconWrap>
                  <MusicMasteringIcon />
                </ButtonIconWrap>
                <ButtonText>
                  <H4>{t('master-a-track')}</H4>
                  <ContentS>
                    {t('master-a-track-description', {
                      defaultValue: 'Enhance your music in one click',
                    })}
                  </ContentS>
                </ButtonText>
              </OnboardingMainButton>
              <OnboardingMainButton
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'artist hub',
                  });
                  history.push('/products/artist-hub');
                }}
              >
                <ButtonIconWrap>
                  <ArtistHubIcon />
                </ButtonIconWrap>
                <ButtonText>
                  <H4>{t('try-our-promo-tools')}</H4>
                  <ContentS>
                    {t('try-our-promo-tools-description', {
                      defaultValue: 'Create web presence in minutes',
                    })}
                  </ContentS>
                </ButtonText>
              </OnboardingMainButton>
            </OnboardinEndMainOptions>
            <OnboardingBaseButton
              data-test-id="Onboarding-Continue"
              onClick={() => {
                handleSubmit('FT Clicked step 3 onboarding', {
                  link_clicked: 'dashboard',
                });
              }}
            >
              <H4>{t('explore-on-my-own')}</H4>
            </OnboardingBaseButton>
          </>
        )}
        {activeStep != Step.OUTRO && (
          <NavBlock>
            <Button
              appearance="fill"
              size="large"
              testId={`Onboarding-Continue`}
              text={t('continue')}
              onClick={() => dispatch({ type: ActionTypes.CONTINUE })}
              primary={
                !(activeStep === Step.INTRO
                  ? !!errors.userCategory
                  : activeStep === Step.REGULAR
                    ? !!errors.genreId || !!errors.artistName
                    : activeStep === Step.LABEL
                      ? !!errors.labelName ||
                        !!errors.numberOfArtists ||
                        !!errors.genreId
                      : false)
              }
            />
          </NavBlock>
        )}
        {activeStep === Step.INTRO && (
          <ContentS>
            <Trans
              i18nKey="skip-onboarding"
              defaults="Don't have time for this? <0>Skip</0>"
              components={[
                <a
                  key="link"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: ActionTypes.SKIP });
                  }}
                  href="#"
                >
                  0
                </a>,
              ]}
            />
          </ContentS>
        )}
      </ContentLayout>
    </SimpleLayoutStyled>
  );
};
