import { css } from '@emotion/react';
import { UploadIcon, ContentM, PlusIcon } from '@imus/base-ui';
import { TracksBrowserDialog } from 'components';
import { TracksUploader } from 'imdshared';
import { Card, Button } from 'imdui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TrackUploadCard = ({
  onSelect,
  selected,
}: {
  onSelect: (ids: (number | string)[]) => void;
  selected: (number | string)[];
}) => {
  const { t } = useTranslation();
  const [openSelector, setOpenSelector] = useState(false);
  return (
    <Card style={{ padding: '32px', width: '100%' }}>
      <Card
        secondary
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
          padding: 64px;
          border-radius: 8px;
          flex: 1 0 0;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
          `}
        >
          <UploadIcon
            css={css`
              width: 144px !important;
              height: 144px !important;
            `}
          />
          <ContentM secondary>
            {t('drag-and-drop-tracks-mastering-subtitle')}
          </ContentM>
        </div>
        <div
          css={css`
            background: var(--outline-var, #4c4c4c);
            height: 1px;
            width: 100%;
          `}
        />

        <div
          css={css`
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            gap: 8px;
            align-self: stretch;
            flex-wrap: wrap;
          `}
        >
          <Button
            primary
            text={t('import-from-library')}
            iconLeft={PlusIcon}
            onClick={() => setOpenSelector(true)}
          />
          <TracksUploader
            onAddTempTracks={(tempIds) => {
              onSelect(tempIds);
            }}
          >
            <Button
              primary
              text={t('upload-tracks')}
              iconLeft={UploadIcon}
              onClick={() => {
                //
              }}
            />
          </TracksUploader>
        </div>
      </Card>
      <TracksBrowserDialog
        isOpen={openSelector}
        isDisabled={(t) => !t.hasAudioUploaded}
        disabledTracks={selected}
        onClose={() => {
          setOpenSelector(false);
        }}
        onTracksSelected={(ids) => {
          onSelect(ids);
          setOpenSelector(false);
        }}
      />
    </Card>
  );
};
