import styled from '@emotion/styled';

const SimpleStepperBase = ({
  style,
  className,
  length,
  activeIndex,
}: {
  style?: React.CSSProperties;
  className?: string;
  length: number;
  activeIndex: number;
}) => {
  return (
    <div className={className} style={style}>
      {new Array(length).fill(0).map((_v, idx) => {
        return (
          <svg
            key={idx}
            width="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="4"
              cy="4"
              r="4"
              fillOpacity={activeIndex === idx + 1 ? '1' : '0.1'}
              fill="var(--accent)"
            />
          </svg>
        );
      })}
    </div>
  );
};

export const SimpleStepper = styled(SimpleStepperBase)`
  display: flex;
  gap: 12px;
`;
