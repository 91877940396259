import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChevronLeftIcon, PlusIcon } from '@imus/base-ui';
import { Button, Window } from 'imdui';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import {
  MasteringTrack,
  MasteringTrackList,
  MasteringTrackSection,
} from './MasteringTrackList';
import {
  useFileUploadRequestProvider,
  useIsUploadingTracks,
  useTracks,
} from 'imddata';
import { MasteringStyleSelectorModal } from './MasteringStyleSelectorModal';
import { TrackUploadCard } from './TrackUploadCard';
import { MASTERING_STEP } from './constants';
import { MasteringStepper } from './MasteringStepper';
import { MasteringTrackVersions } from './MasteringTrackVersions';
import { useProductPage, useProductPageActions } from 'components';

const FormLayout = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
`;

// TODO add handling of Upload errors
const MasteringTrackConnected = ({
  id,
  ...props
}: React.ComponentProps<typeof MasteringTrack> & { id: string | number }) => {
  const { t } = useTranslation();
  const fileUpload = useFileUploadRequestProvider({
    entity: 'tracks',
    id,
  });

  return (
    <MasteringTrack
      {...props}
      loading={fileUpload.uploading ? t('uploading') : false}
    />
  );
};

// TODO
// - Prevent Generate previews/Next step when all audios are not ready on tracks
// - Fix track browser showing only uploaded tracks and not all

const FORM_STEPS = [
  MASTERING_STEP.GENERATE_PREVIEWS,
  MASTERING_STEP.COMPARE,
  MASTERING_STEP.CHOOSE_TRACKS,
];

export const TrackMasteringForm = () => {
  const { t } = useTranslation();
  const [openAddTracksModal, setOpenAddTracksModal] = useState(false);

  const productState = useProductPage();
  const { changeProduct } = useProductPageActions();

  const [trackIds, setTrackIds] = useState<(string | number)[]>(() => {
    return (productState.state.data.trackIds || []) as (string | number)[];
  });
  const [step, setStep] = useState<MASTERING_STEP>(
    () => MASTERING_STEP.UPLOAD_TRACKS
  );

  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);
  const handleSelectVersions = useMemo(
    () =>
      step === MASTERING_STEP.CHOOSE_TRACKS
        ? (id: string, checked: boolean) => {
            setSelectedVersions((versionsState) => {
              if (checked && !selectedVersions.includes(id)) {
                return [...versionsState, id];
              }
              if (!checked) {
                return versionsState.filter((v) => v !== id);
              }
              return versionsState;
            });
          }
        : undefined,
    [step]
  );

  const [openStyleSelector, setOpenStyleSelector] = useState(false);
  const [globalAudioReferenceId, setGlobalAudioReferenceId] =
    useState<string>();

  const { entries: tracks, request } = useTracks({ ids: trackIds });
  useEffect(() => {
    if (!trackIds.length) {
      setStep(MASTERING_STEP.UPLOAD_TRACKS);
      return;
    }
    if (step === MASTERING_STEP.UPLOAD_TRACKS) {
      setStep(MASTERING_STEP.GENERATE_PREVIEWS);
    }
  }, [trackIds]);

  const isUploadingTracks = useIsUploadingTracks({ ids: trackIds });
  const loading = isUploadingTracks || request.loading || !!tracks.find(track => track.temporary);

  useEffect(() => {
    if (tracks.length > 0) {
      setTrackIds(tracks.map((t) => t.id));
      changeProduct({ trackIds: tracks.map((t) => t.id) });
    }
  }, [tracks.map((t) => t.id).join('')]);

  return (
    <FormLayout>
      <div
        css={css`
          flex: 312px;
          max-width: 312px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <MasteringStepper step={step} loading={loading || isUploadingTracks} />
      </div>
      {step === MASTERING_STEP.UPLOAD_TRACKS && (
        <TrackUploadCard onSelect={setTrackIds} selected={trackIds} />
      )}
      {FORM_STEPS.includes(step) && (
        <MasteringTrackList
          style={{ width: '100%' }}
          actions={
            <>
              {step === MASTERING_STEP.GENERATE_PREVIEWS && (
                <>
                  <Button
                    text={t('add-tracks')}
                    iconLeft={PlusIcon}
                    appearance="fill"
                    onClick={() => setOpenAddTracksModal(true)}
                  />
                  <Button
                    primary
                    appearance="fill"
                    disabled={loading}
                    text={t('continue')}
                    onClick={() => setOpenStyleSelector(true)}
                  />
                </>
              )}
              {step === MASTERING_STEP.COMPARE && (
                <>
                  <Button
                    text={t('back')}
                    iconLeft={ChevronLeftIcon}
                    appearance="fill"
                    onClick={() => setStep(MASTERING_STEP.GENERATE_PREVIEWS)}
                  />
                  <Button
                    primary
                    appearance="fill"
                    disabled={loading}
                    text={t('select-tracks')}
                    onClick={() => setStep(MASTERING_STEP.CHOOSE_TRACKS)}
                  />
                </>
              )}
              {step === MASTERING_STEP.CHOOSE_TRACKS && (
                <>
                  <Button
                    text={t('back')}
                    iconLeft={ChevronLeftIcon}
                    appearance="fill"
                    onClick={() => setStep(MASTERING_STEP.COMPARE)}
                  />
                  <Button
                    primary
                    appearance="fill"
                    disabled={loading}
                    text={t('order')}
                    onClick={() => {
                      // TODO make order
                    }}
                  />
                </>
              )}
            </>
          }
        >
          {tracks.map((track, idx) => {
            return (
              <MasteringTrackSection key={track.id}>
                <MasteringTrackConnected
                  id={track.id}
                  onRemove={
                    track.temporary
                      ? undefined
                      : () =>
                          setTrackIds(trackIds.filter((id) => id !== track.id))
                  }
                  title={track.defaultName.title}
                  index={idx + 1}
                />
                {!track.temporary && (
                  <MasteringTrackVersions
                    style={
                      step !== MASTERING_STEP.COMPARE &&
                      step !== MASTERING_STEP.CHOOSE_TRACKS
                        ? { display: 'none' }
                        : undefined
                    }
                    onSelect={handleSelectVersions}
                    selected={selectedVersions}
                    originalAudioId={track.fileId}
                    version={globalAudioReferenceId}
                    trackId={track.id}
                  />
                )}
              </MasteringTrackSection>
            );
          })}
        </MasteringTrackList>
      )}
      {step === MASTERING_STEP.GENERATE_PREVIEWS && (
        <MasteringStyleSelectorModal
          onSelect={(style) => {
            setStep(MASTERING_STEP.COMPARE);
            setOpenStyleSelector(false);
            setGlobalAudioReferenceId(style);
          }}
          open={openStyleSelector}
          onRequestClose={() => {
            setOpenStyleSelector(false);
          }}
        />
      )}
      <Window
        title={t('add-tracks')}
        isOpen={openAddTracksModal}
        close={() => setOpenAddTracksModal(false)}
      >
        <TrackUploadCard
          onSelect={(ids) => {
            setOpenAddTracksModal(false);
            setTrackIds([...trackIds, ...ids]);
          }}
          selected={trackIds}
        />
      </Window>
    </FormLayout>
  );
};
