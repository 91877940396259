import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  MusicUploadIcon,
  ContentM,
  HelpIcon,
  MusicMasteringIcon,
} from '@imus/base-ui';
import { Card, IconButton } from 'imdui';
import { useTranslation } from 'react-i18next';
import { MASTERING_STEP } from './constants';
import { SimpleStepper } from 'components';
import { LongLoader } from './LongLoader';

const StepTitle = styled.span`
  color: var(--on-surface, #fcfcfc);
  color: var(--on-surface, color(display-p3 0.9882 0.9882 0.9882));
  text-align: center;
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 121.739% */
  text-transform: uppercase;
`;

const circleBlur = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="312"
    height="328"
    viewBox="0 0 312 328"
    fill="none"
  >
    <g opacity="0.1" filter="url(#filter0_f_385_3014)">
      <circle cx="156" r="128" fill="#73FC8A" />
    </g>
    <defs>
      <filter
        id="filter0_f_385_3014"
        x="-172"
        y="-328"
        width="656"
        height="656"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_385_3014"
        />
      </filter>
    </defs>
  </svg>
);

const MasteringStepBase = ({
  stepsLength,
  activeStep,
  title,
  icon,
  subtitle,
  className,
  style,
  loading,
}: {
  title: string;
  className?: string;
  style?: React.CSSProperties;
  icon: React.ReactNode;
  subtitle: string;
  loading?: boolean;
  stepsLength: number;
  activeStep: number;
}) => {
  return (
    <Card secondary className={className} style={style}>
      <div
        css={css`
          width: 100%;
          height: 256px;
          aspect-ratio: 1/1;
          position: absolute;
          top: -128px;
        `}
      >
        {circleBlur}
      </div>
      {loading && (
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            gap: 16px;
          `}
        >
          <LongLoader size="xLarge" />
        </div>
      )}
      {!loading && (
        <>
          <SimpleStepper length={stepsLength} activeIndex={activeStep} />
          <div
            css={css`
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              align-items: center;
              gap: 16px;
            `}
          >
            {icon}
            <StepTitle>{title}</StepTitle>
            <ContentM secondary>{subtitle}</ContentM>
          </div>
        </>
      )}
    </Card>
  );
};

export const MasteringStep = styled(MasteringStepBase)`
  display: flex;
  flex: 312px;
  min-height: 416px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  position: relative;
  aspect-ratio: 3/4;
`;

const NoticeCard = styled(Card)`
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const stepIconStyle = css`
  width: 80px !important;
  height: 80px !important;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  --on-surface: var(--accent);
`;

const STEP_COUNT = 3;

export const MasteringStepper = ({
  step,
  loading,
}: {
  step: MASTERING_STEP;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  switch (step) {
    case MASTERING_STEP.UPLOAD_TRACKS:
      return (
        <>
          <MasteringStep
            loading={loading}
            stepsLength={STEP_COUNT}
            activeStep={1}
            title={t('upload-tracks')}
            subtitle={t('upload-tracks-mastering-subtitle')}
            icon={<MusicUploadIcon css={stepIconStyle} />}
          />

          <NoticeCard>
            <ContentM secondary>{t('mastering-notice-track-upload')}</ContentM>
            <IconButton inline>
              <HelpIcon />
            </IconButton>
          </NoticeCard>
        </>
      );
    case MASTERING_STEP.GENERATE_PREVIEWS:
      return (
        <MasteringStep
          loading={loading}
          stepsLength={STEP_COUNT}
          activeStep={1}
          title={t('generate-previews')}
          subtitle={t('generate-previews-mastering-subtitle')}
          icon={<MusicMasteringIcon css={stepIconStyle} />}
        />
      );
    case MASTERING_STEP.COMPARE:
      return (
        <MasteringStep
          loading={loading}
          stepsLength={STEP_COUNT}
          activeStep={2}
          title={t('compare-tracks')}
          subtitle={t('compare-tracks-mastering-subtitle')}
          icon={<MusicUploadIcon css={stepIconStyle} />}
        />
      );
    case MASTERING_STEP.CHOOSE_TRACKS:
      return (
        <MasteringStep
          loading={loading}
          stepsLength={STEP_COUNT}
          activeStep={3}
          title={t('choose-tracks-for-mastering')}
          subtitle={t('choose-tracks-for-mastering-subtitle')}
          icon={<MusicUploadIcon css={stepIconStyle} />}
        />
      );
  }
};
