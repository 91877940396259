import { useCallback, useEffect, useState } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Button } from 'imdui';
import { useSingelFileUploadManager } from 'imdshared';
import { useTranslation } from 'react-i18next';
import { UploadIcon } from '@imus/base-ui';

export const MasteringReferenceAudioUploader = ({
  onChange,
}: {
  onChange: (file: any) => void;
  style?: React.CSSProperties;
}) => {
  const [droppedAudio, setDroppedAudio] = useState<File>();
  const {
    preview: result,
    upload,
    request,
  } = useSingelFileUploadManager({
    handlerData: {},
    handler: 'masteringReferenceAudio',
  });

  useEffect(() => {
    if (droppedAudio) {
      upload(droppedAudio);
    }
  }, [droppedAudio]);

  const [_error, setError] = useState('');
  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles, failedFiles) => {
      if (acceptedFiles[0]) {
        setDroppedAudio(acceptedFiles[0]);
        setError('');
      }
      if (failedFiles[0]) {
        setError(failedFiles[0].errors[0]?.code);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    accept: {
      'audio/wav': ['.wav'],
      'audio/wave': ['.wav'],
      'audio/x-wav': ['.wav'],
      'audio/x-pn-wav': ['.wav'],
    },
  });

  useEffect(() => {
    if (result) {
      onChange({
        url: result.downloadUrl,
        filename: result.fileName,
        extension: result.extension,
      });
    }
  }, [result]);
  const { t } = useTranslation();

  return (
    <>
      <input {...getInputProps()} />
      <Button
        {...getRootProps()}
        style={{ width: '100%' }}
        iconLeft={UploadIcon}
        appearance="elevated"
        text={t('upload-new-reference-track')}
        disabled={request.uploading}
        showLoading={request.uploading}
      />
    </>
  );
};
