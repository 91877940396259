import React, { useState, useCallback, useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Headline, SideWindow, IconButton, Icons } from 'imdshared';
import styled from '@emotion/styled';
import TracksSearch from '../TracksSearch';
import { ActionBar } from './components';
import type { Track } from 'imddata/types/entities';

const SideWindowStyled = styled(SideWindow)`
  flex: 1;
  z-index: 1000;
`;

const Header = styled.div`
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 15px 30px;
`;

const TrackList = styled.div`
  min-height: 100vh;

  > div:first-child {
    padding: 0 24px;
    border: none;
  }

  > div:nth-child(2) {
    padding: 0 6px;
  }
`;

type Props = {
  onClose: () => void;
  onTracksSelected: (tracks: number[]) => void;
  onSelect?: (tracks: number[]) => void;
  disabledTracks?: (number | string)[];
  selectedTracks?: number[];
  isDisabled?: (t: Track) => boolean;
  noGrouping?: boolean;
  isOpen: boolean;
  hideCreateTrack?: boolean;
  hideUploadTrack?: boolean;
  defaultSearchQuery?: Record<string, unknown>;
};

const setTracks = (tracks?: number[]) =>
  tracks && Array.isArray(tracks) ? tracks : [];

export default function TracksBrowserDialog(props: Props) {
  const [selectedTracks, setSelectedTracks] = useState<number[]>(() =>
    setTracks(props.selectedTracks)
  );
  useEffect(() => {
    setSelectedTracks(setTracks(props.selectedTracks));
  }, [props.selectedTracks]);
  const {
    onSelect,
    onClose,
    onTracksSelected,
    isDisabled,
    disabledTracks,
    defaultSearchQuery,
    noGrouping,
    isOpen,
    hideUploadTrack = true,
    hideCreateTrack = true,
  } = props;

  const handleAddClicked = useCallback(() => {
    const addedTracks = selectedTracks.filter((x) =>
      disabledTracks ? disabledTracks.indexOf(x) < 0 : true
    );
    onTracksSelected(addedTracks);
    setSelectedTracks([]);
  }, [selectedTracks, disabledTracks, onTracksSelected]);

  return (
    <SideWindowStyled isOpen={isOpen} isLarge={true}>
      {(wrapper: any) => (
        <Translation>
          {(t) => (
            <div>
              <Header>
                <Headline>{t('tracks')}</Headline>

                <IconButton icon={Icons.actions.close} onClick={onClose} />
              </Header>

              <TrackList>
                {wrapper && (
                  <TracksSearch
                    noGrouping={noGrouping}
                    defaultSearchQuery={defaultSearchQuery}
                    isDisabled={isDisabled}
                    scrollElement={wrapper}
                    stickyTopThreshold={85}
                    respectTopConstraint={false}
                    onChangeSelection={onSelect || setSelectedTracks}
                    disabledTracks={disabledTracks}
                    selectedTracks={selectedTracks}
                    hideCreateTrack={hideCreateTrack}
                    hideUploadTrack={hideUploadTrack}
                  />
                )}
              </TrackList>

              <ActionBar
                countSelectedTracks={selectedTracks.length}
                onClickAdd={handleAddClicked}
                onClickCancel={onClose}
              />
            </div>
          )}
        </Translation>
      )}
    </SideWindowStyled>
  );
}
