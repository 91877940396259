import styled from '@emotion/styled';
import { accentThemes, BodyS } from '@imus/base-ui';
import { H4, Button } from 'imdui';

const SubscribeRow = styled.div`
  ${accentThemes.tertiary}
  display: flex;
  gap: 24px;
  align-items: center;
`;

const SubscribeRowText = styled.div`
  display: grid;
  flex: 1;
  gap: 4px;
  ${BodyS} {
    color: var(--fg-2);
  }
`;
export const SubscribeUpsell = ({
  title,
  description,
  actionText,
  onClickSubscribe,
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  description: string;
  actionText: string;
  onClickSubscribe: (e: React.MouseEvent) => void;
}) => {
  return (
    <SubscribeRow className={className} style={style}>
      <SubscribeRowText>
        <H4>{title}</H4>
        <BodyS>{description}</BodyS>
      </SubscribeRowText>
      <Button
        style={{ flexShrink: '0' }}
        testId="CustomerCommisionRate-upgrade"
        primary={true}
        text={actionText}
        onClick={(e) => onClickSubscribe(e)}
      />
    </SubscribeRow>
  );
};
