//
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Switch, Route } from 'react-router-dom';
import {
  CompletedOrder,
  FailedOrder,
  Mastering,
  DeliveryUpdate,
  NewDeliveryProduct,
  ReleaseWizard,
  AllOrdersOverview,
  // ContentId,
  ReleasePageWizard,
  MusicDistribution,
  InstantMastering,
  CustomProduct,
} from './screens';

const Order = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route path={`${match.path}/overview`} component={AllOrdersOverview} />
    <Route
      exact={true}
      path={`${match.path}/update`}
      component={DeliveryUpdate}
    />
    <Route
      exact={true}
      path={`${match.path}/:product/new`}
      component={NewDeliveryProduct}
    />
    <Route path={`${match.path}/md/:bundleId`} component={MusicDistribution} />
    <Route
      path={`${match.path}/ycid/:id`}
      render={() => <Redirect to="/products" />}
    // component={ContentId}
    />
    <Route path={`${match.path}/mastering/:id`} component={Mastering} />
    <Route
      path={`${match.path}/instant-mastering`}
      component={InstantMastering}
    />
    <Route path={`${match.path}/release/:id`} component={ReleaseWizard} />
    <Route
      path={`${match.path}/release-page/:id`}
      component={ReleasePageWizard}
    />
    <Route
      path={`${match.path}/premium-label`}
      render={() => <Redirect to="/" />}
    />
    <Route path={`${match.path}/complete`} component={CompletedOrder} />
    <Route path={`${match.path}/failed`} component={FailedOrder} />
    <Route path={`${match.path}/:productId`} component={CustomProduct} />
  </Switch>
);

export default Order;
