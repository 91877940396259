import { useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { ProductPageContext } from 'components';
import NavBar from '../../shared/NavBar';
import type { ProductPageContextType } from '../../shared/ProductWizard';
import { ProductPriceContext, ProductScreen } from '../../shared';
import { TrackMasteringForm } from './TrackMasteringForm';

const MASTER = 'preview';

const InstantMasteringManager = () => {
  return null;
};

const INSTANT_MASTERING_STEPS = [{ id: MASTER, to: MASTER, label: 'release' }];

export const INSTANT_MASTERING_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'instant-mastering',
  title: 'instant-mastering',
  initialData: { tracks: [] },
  steps: INSTANT_MASTERING_STEPS,
  initialStepsState: INSTANT_MASTERING_STEPS,
  entity: 'instantMasterings',
  overviewEntity: undefined,
  StepperStateManager: InstantMasteringManager,
  NavBarRenderer: function NavBarRenderer({ stepId, ...props }) {
    switch (stepId) {
      default:
        return <NavBar {...props} />;
    }
  },
  RedirectManager: function RedirectManager({ match }) {
    return <Redirect to={`${match.url}/${MASTER}`} />;
  },
  StepRenderer: function StepsRenderer({ stepId }) {
    // const id = useProductId();

    switch (stepId) {
      case MASTER: {
        return <TrackMasteringForm />;
      }
      default:
        return <span>{`${stepId} :not-found`}</span>;
    }
  },
};

const noPrice = {
  error: true,
  calculating: false,
  recalculate: () => {
    throw new Error('should not be called');
  },
};

export function InstantMastering(_props: RouteComponentProps) {
  const value = useMemo(() => {
    return {
      id: 'mastering',
      ...INSTANT_MASTERING_CONTEXT,
    };
  }, []);

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceContext.Provider value={noPrice}>
        <ProductScreen title="mastering">
          <TrackMasteringForm />
        </ProductScreen>
      </ProductPriceContext.Provider>
    </ProductPageContext.Provider>
  );
}
