//
import React, { useEffect, useMemo, Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useCreateEntity, useDeliveryRegions, useCurrentUser } from 'imddata';
import { useDispatch } from 'react-redux';
import { actions } from 'imddata/actionTypes/ui/selection';
import type { RouteComponentProps } from 'react-router-dom';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { EventTypes } from 'redux-segment';
import { MASTERING_CONTEXT } from '../Mastering';
import { INSTANT_MASTERING_CONTEXT } from '../InstantMastering';
import { MD_CONTEXT } from '../MusicDistribution';

import { Stepper, ProductLoadingScreen } from '../../shared';

import { actions as productActions } from 'redux/actions/ui/products';
import { PageTemplate } from 'components';
import { ConfirmationWindow, SubscriptionUpsellContext } from 'imdshared';

type SupportedProductTypes =
  | 'md'
  | 'ycid'
  | 'mastering'
  | 'instant-mastering'
  | 'release-page'
  | 'release';

const emptySteps: never[] = [];

const getDeliveryProductMeta = (product: SupportedProductTypes) => {
  switch (product) {
    case 'md':
      return MD_CONTEXT;
    case 'instant-mastering':
      return INSTANT_MASTERING_CONTEXT;
    case 'mastering':
      return MASTERING_CONTEXT;
    case 'release':
      return {
        steps: emptySteps,
        title: 'release',
      };
    case 'release-page':
      return {
        steps: emptySteps,
        title: 'release-page',
      };
    default:
      throw new Error('Attempt to create delivery for non deliverable product');
  }
};

const useRedirectOnError = (error?: any) => {
  const history = useHistory();
  useEffect(() => {
    if (error && error.details?.releasePageId) {
      history.push(`/order/release-page/${error.details?.releasePageId}`);
    }
  }, [error]);
};

const DELIVERY_BUNDLE_WITHS = {
  with: 'tracks,releases,countries,shops',
};

const useCreateProduct = ({
  releaseId,
  tracks,
  labelId,
  artistIds,
  product,
  copyrightText,
  createBundle,
}: {
  artistIds?: number[];
  releaseId?: number | string;
  tracks?: number[];
  copyrightText?: string;
  labelId?: number;
  product: SupportedProductTypes;
  createBundle: ReturnType<typeof useCreateEntity>['createEntry'];
}) => {
  const { entry: user } = useCurrentUser();
  const { entries: regions } = useDeliveryRegions();
  const countries = regions.reduce<{ countryId: number; status: string }[]>(
    (acc, r) => [
      ...acc,
      ...r.countries.map((rc) => ({
        countryId: rc.countryId,
        status: 'deliver',
      })),
    ],
    []
  );
  useEffect(() => {
    switch (product) {
      case 'md': {
        if (!releaseId) return;
        createBundle(
          {
            query: { ...DELIVERY_BUNDLE_WITHS },
            data: {
              allowPaymentForCountriesCustomization: true,
              allowPaymentForAdditionalVolumes: true,
              countries,
              releases: [{ releaseId }],
            },
          },
          {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'FT Order Product',
                properties: {
                  product: 'music-distribution',
                  existingRelease: true,
                  releaseId,
                },
              },
            },
          }
        );
        break;
      }
      case 'instant-mastering': {
        // TODO: Reset local state if tracks supplied
        break;
      }
      case 'mastering': {
        createBundle(
          {
            query: { with: 'tracks' },
            data: {
              languageId: 'en',
              type: 'instantMasteringTracks,tracks',
              pricePlanId: undefined,
              addToContentId: 1,
              tracks:
                tracks?.map((trackId) => ({
                  trackId,
                })) || [],
            },
          },
          {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'FT Order Product',
                properties: {
                  product,
                  tracks,
                },
              },
            },
          }
        );
        break;
      }
      case 'release-page': {
        if (!releaseId) return;
        createBundle(
          {
            data: {
              releaseId,
              isPremium: false,
            },
          },
          {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'FT Order Product',
                properties: {
                  product,
                },
              },
            },
          }
        );
        break;
      }
      case 'release':
        createBundle({
          data: {
            artists: artistIds
              ? artistIds.map((id) => ({ artistId: id, role: 'main' }))
              : [],
            names: [{ languageId: user?.defaultLanguageId }],
          },
        });
        break;
      case 'ycid': {
        createBundle(
          {
            query: { with: 'tracks' },
            data: {
              languageId: 'en',
              type: 'tracks',
              addToContentId: 1,
              pricePlanId: undefined,
              countries,
              copyrightText,
              labelId,
              tracks:
                tracks?.map((trackId) => ({
                  trackId,
                  add_to_content_id: true,
                })) || [],
            },
          },
          {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'FT Order Product',
                properties: {
                  product,
                  tracks,
                },
              },
            },
          }
        );
        break;
      }
      default: {
        console.error('what');
      }
    }
  }, [releaseId, tracks, product]);
};

const releaseBasedProducts: SupportedProductTypes[] = ['md', 'release-page'];

const useCreateReleaseForDelivery = ({
  tracks = [],
  releaseId,
  artistIds,
  createRelease,
  product,
}: {
  releaseId?: number | string;
  tracks?: number[];
  artistIds?: number[];
  product: SupportedProductTypes;
  createRelease: ReturnType<typeof useCreateEntity>['createEntry'];
}) => {
  const { entry } = useCurrentUser();

  useEffect(() => {
    if (releaseId || releaseBasedProducts.indexOf(product) < 0) return;

    createRelease({
      query: { with: 'tracks,artists' },
      data: {
        artists: artistIds
          ? artistIds.map((id) => ({ artistId: id, role: 'main' }))
          : [],
        names: [{ languageId: entry?.defaultLanguageId }],
        volumes: [tracks.map((trackId) => ({ trackId }))],
      },
    });
  }, []);
};

const useGeneratedComponentId = (seed: string | number) => {
  return useMemo(() => `${Date.now()}-${seed}`, [seed]);
};

const matchProductToEntity = (product: SupportedProductTypes) => {
  switch (product) {
    case 'md':
    case 'ycid':
      return 'deliveryBundles';
    case 'mastering':
      return 'instantMasterings';
    case 'release-page':
      return 'releasePages';
    case 'release':
      return 'releases';
    default:
      throw new Error('Attempt to create delivery for non deliverable product');
  }
};

type LocationState = {
  hidePrice?: boolean;
  copyrightText?: string;
  releaseId?: number;
  tracks?: number[];
  pricePlanId?: string;
  countries?: number[];
  artistIds?: number[];
  labelId?: number;
};

const supportedProductIds: SupportedProductTypes[] = [
  'md',
  'ycid',
  'release',
  'mastering',
  'release-page',
];

const isDeliveryProduct = (
  product?: string
): product is SupportedProductTypes => {
  if (!product) return false;

  return supportedProductIds.indexOf(product as SupportedProductTypes) >= 0;
};

const useClearSelection = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.updateSelection({
        entity: 'tracks',
        selected: [],
      })
    );
    dispatch(
      actions.updateSelection({
        entity: 'contributors',
        selected: [],
      })
    );
    dispatch(
      actions.updateSelection({
        entity: 'publishers',
        selected: [],
      })
    );
  }, []);
};

export default function NewProduct({ match }: RouteComponentProps) {
  const { product } = useParams<{ product: string }>();
  const { state } = useLocation<LocationState>();
  if (!isDeliveryProduct(product)) {
    throw new Error('Attempt to create delivery for non deliverable product');
  }

  const productEntity = matchProductToEntity(product);

  const {
    releaseId: passedReleaseId,
    tracks,
    copyrightText,
    // countries,
    hidePrice,
    labelId,
    artistIds,
  } = state || {};

  const requestStoreKeyRelease = useGeneratedComponentId(
    `${passedReleaseId || ''}-New-${product}-release`
  );

  const requestStoreKeyBundle = useGeneratedComponentId(
    `${passedReleaseId || ''}-New-${product}-bundle`
  );

  const {
    createEntry: createRelease,
    createdId: createdReleaseId,
    request: releaseRequest,
  } = useCreateEntity({
    entity: 'releases',
    requestStoreKey: requestStoreKeyRelease,
  });

  const {
    createEntry: createBundle,
    createdId: createdBundleId,
    request: productRequest,
  } = useCreateEntity({
    entity: productEntity,
    requestStoreKey: requestStoreKeyBundle,
  });

  const failed = releaseRequest.failed || productRequest.failed;

  const releaseId = passedReleaseId || createdReleaseId;

  useClearSelection();

  useCreateReleaseForDelivery({
    product,
    tracks,
    createRelease,
    artistIds,
    releaseId,
  });
  useCreateProduct({
    artistIds,
    labelId,
    tracks,
    copyrightText,
    product,
    createBundle,
    releaseId,
  });
  const { t } = useTranslation();

  const { steps, title } = getDeliveryProductMeta(product);
  const history = useHistory();

  const stepper = <Stepper disabled={true} steps={steps} match={match} />;

  useRedirectOnError(productRequest.error);

  const dispatch = useDispatch();

  useEffect(() => {
    if (createdBundleId) {
      if (product === 'ycid') {
        dispatch(
          productActions.changeProductPage({
            product,
            id: createdBundleId as any,
            data: { hidePrice },
          })
        );
      }
      history.replace(`/order/${product}/${createdBundleId}`);
    }
  }, [createdBundleId]);
  const { open: openUpsell } = useContext(SubscriptionUpsellContext);

  if (
    product === 'release-page' &&
    productRequest.statusCode === 422 &&
    productRequest.errorMessage === 'Release page limit reached'
  ) {
    return (
      <PageTemplate title={t('release-page')}>
        <ConfirmationWindow
          isOpen={true}
          message={t('limit-reached-on-artist-hub-standalone')}
          title={t('limit-exceeded')}
          onRequestClose={() => {
            history.push('/');
          }}
          cancelLabel={t('subscribe')}
          onCancel={() => {
            openUpsell({ section: 'promo-tools' });
          }}
        />
      </PageTemplate>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
      </Helmet>

      <ProductLoadingScreen
        title={t(title)}
        failedText={
          failed
            ? t('creating-new-product-failed-ask-support', { context: product })
            : undefined
        }
        stepper={stepper}
      />
    </>
  );
}
