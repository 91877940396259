//
import * as R from 'ramda';
import {
  createRequestActions,
  factoryEntityTypes,
  factoryEntityActions,
} from '../base';
import type { AsyncActionTypes } from '../base/asyncCrudActionTypeFactory';
import type { FactoryEntityActions } from '../base/createRequestActions';

// Creates data structure for storing actions and types
// that handles CRUD operations for each entity
export function generateActionsAndTypes(entityKey: string) {
  const genericTypes = factoryEntityTypes(entityKey);
  const genericActions = factoryEntityActions(genericTypes, entityKey);
  const defaultAcc = {
    types: {},
    actions: {},
  };
  const related = defaultAcc;
  return {
    types: {
      ...genericTypes,
      ...related.types,
    },
    actions: {
      ...genericActions,
      ...related.actions,
    },
  };
}

type FileTypes = {
  updateProgress: string;
  upload: AsyncActionTypes;
  delete: AsyncActionTypes;
  confirm: AsyncActionTypes;
};
const setFileActionsAndTypes = (
  actions: {
    updateProgress: (payload: { id: string | number; progress: number }) => {
      type: string;
      payload: { id: string | number; progress: number };
    };
    upload: FactoryEntityActions;
    delete: FactoryEntityActions;
    confirm: FactoryEntityActions;
  },
  types: FileTypes,
  key = 'file'
) =>
  R.compose(
    R.set(R.lensPath(['actions', key]), actions),
    R.set(R.lensPath(['types', key]), types)
  );

const generateFileActionTypes = (
  entityKey: string,
  uploadedLabel = 'FILE'
): FileTypes => ({
  updateProgress: `@${entityKey}/UPDATE_${uploadedLabel}_UPLOAD_PROGRESS`,
  upload: {
    ABORT: `@${entityKey}/START_${uploadedLabel}_UPLOAD_ABORT`,
    SUCCESS: `@${entityKey}/START_${uploadedLabel}_UPLOAD_SUCCESS`,
    FAILURE: `@${entityKey}/START_${uploadedLabel}_UPLOAD_FAILURE`,
    REQUEST: `@${entityKey}/START_${uploadedLabel}_UPLOAD_REQUEST`,
  },
  delete: {
    SUCCESS: `@${entityKey}/DELETE_${uploadedLabel}_SUCCESS`,
    FAILURE: `@${entityKey}/DELETE_${uploadedLabel}_FAILURE`,
    REQUEST: `@${entityKey}/DELETE_${uploadedLabel}_REQUEST`,
  },
  confirm: {
    SUCCESS: `@${entityKey}/CONFIRM_${uploadedLabel}_UPLOAD_SUCCESS`,
    FAILURE: `@${entityKey}/CONFIRM_${uploadedLabel}_UPLOAD_FAILURE`,
    REQUEST: `@${entityKey}/CONFIRM_${uploadedLabel}_UPLOAD_REQUEST`,
  },
});

const salesImportsFileActionTypes = generateFileActionTypes(
  'salesImports',
  'FILE'
);

const salesImportsFileActions = {
  updateProgress: (payload: { id: number | string; progress: number }) => ({
    type: salesImportsFileActionTypes.updateProgress,
    payload,
  }),
  upload: createRequestActions(salesImportsFileActionTypes.upload),
  delete: createRequestActions(salesImportsFileActionTypes.delete),
  confirm: createRequestActions(salesImportsFileActionTypes.confirm),
};

const releasesCoverActionTypes = generateFileActionTypes('releases', 'COVER');

const releasesCoverActions = {
  updateProgress: (payload: { id: number | string; progress: number }) => ({
    type: releasesCoverActionTypes.updateProgress,
    payload,
  }),
  upload: createRequestActions(releasesCoverActionTypes.upload),
  delete: createRequestActions(releasesCoverActionTypes.delete),
  confirm: createRequestActions(releasesCoverActionTypes.confirm),
};

const tracksAudioActionTypes = generateFileActionTypes('tracks', 'AUDIO');

const tracksAudioActions = {
  updateProgress: (payload: { id: number | string; progress: number }) => ({
    type: tracksAudioActionTypes.updateProgress,
    payload,
  }),
  upload: createRequestActions(tracksAudioActionTypes.upload),
  delete: createRequestActions(tracksAudioActionTypes.delete),
  confirm: createRequestActions(tracksAudioActionTypes.confirm),
};

const filesManagerActionTypes = generateFileActionTypes('fileUpload', 'FILE');

const filesManagerActions = {
  updateProgress: (payload: { id: number | string; progress: number }) => ({
    type: filesManagerActionTypes.updateProgress,
    payload,
  }),
  abort: (payload: { id: number }) => ({
    type: filesManagerActionTypes.upload.ABORT,
    payload,
  }),
  upload: createRequestActions(filesManagerActionTypes.upload),
  delete: createRequestActions(filesManagerActionTypes.delete),
  confirm: createRequestActions(filesManagerActionTypes.confirm),
};

export const filesManager = {
  actions: filesManagerActions,
  types: filesManagerActionTypes,
};

export const entities = {
  admins: generateActionsAndTypes('admins'),
  registerAction: generateActionsAndTypes('registerAction'),
  artistFiles: generateActionsAndTypes('artistFiles'),
  artistGalleries: generateActionsAndTypes('artistGalleries'),
  artistImages: generateActionsAndTypes('artistImages'),
  artistNames: generateActionsAndTypes('artistNames'),
  artistPages: generateActionsAndTypes('artistPages'),
  artistPageActions: generateActionsAndTypes('artistPageActions'),
  artists: generateActionsAndTypes('artists'),
  artistsMetadata: generateActionsAndTypes('artistsMetadata'),
  barcodes: generateActionsAndTypes('barcodes'),
  batchProcessActions: generateActionsAndTypes('batchProcessActions'),
  batchProcesses: generateActionsAndTypes('batchProcesses'),
  blacklistItems: generateActionsAndTypes('blacklistItems'),
  channels: generateActionsAndTypes('channels'),
  contactRequests: generateActionsAndTypes('contactRequests'),
  contributorNames: generateActionsAndTypes('contributorNames'),
  contributors: generateActionsAndTypes('contributors'),
  copyrightClaimItems: generateActionsAndTypes('copyrightClaimItems'),
  copyrightClaims: generateActionsAndTypes('copyrightClaims'),
  countries: generateActionsAndTypes('countries'),
  coverArtworkTemplates: generateActionsAndTypes('coverArtworkTemplates'),
  createOrder: generateActionsAndTypes('createOrder'),
  createTakedown: generateActionsAndTypes('createTakedown'),
  currencies: generateActionsAndTypes('currencies'),
  currencyRates: generateActionsAndTypes('currencyRates'),
  customerActions: generateActionsAndTypes('customerActions'),
  customerPaymentMethods: generateActionsAndTypes('customerPaymentMethods'),
  customers: generateActionsAndTypes('customers'),
  customerSubscriptions: generateActionsAndTypes('customerSubscriptions'),
  customerSubscriptionState: generateActionsAndTypes(
    'customerSubscriptionState'
  ),
  customerSubscriptionStateAction: generateActionsAndTypes(
    'customerSubscriptionStateAction'
  ),
  customerVouchers: generateActionsAndTypes('customerVouchers'),
  dateFormats: generateActionsAndTypes('dateFormats'),
  deliveries: generateActionsAndTypes('deliveries'),
  deliveryBundleActions: generateActionsAndTypes('deliveryBundleActions'),
  deliveryBundleCountries: generateActionsAndTypes('deliveryBundleCountries'),
  deliveryBundleDeliveries: generateActionsAndTypes('deliveryBundleDeliveries'),
  deliveryBundleOverview: generateActionsAndTypes('deliveryBundleOverview'),
  deliveryBundleReleases: generateActionsAndTypes('deliveryBundleReleases'),
  deliveryBundles: generateActionsAndTypes('deliveryBundles'),
  deliveryBundleShops: generateActionsAndTypes('deliveryBundleShops'),
  deliveryBundleTracks: generateActionsAndTypes('deliveryBundleTracks'),
  deliveryBundleUpdateOverview: generateActionsAndTypes(
    'deliveryBundleUpdateOverview'
  ),
  deliveryBundleUpdates: generateActionsAndTypes('deliveryBundleUpdates'),
  deliveryBundleUpgradeOverview: generateActionsAndTypes(
    'deliveryBundleUpgradeOverview'
  ),
  deliveryFiles: generateActionsAndTypes('deliveryFiles'),
  deliveryRegionCountries: generateActionsAndTypes('deliveryRegionCountries'),
  deliveryRegions: generateActionsAndTypes('deliveryRegions'),
  disks: generateActionsAndTypes('disks'),
  downloads: generateActionsAndTypes('downloads'),
  promotions: generateActionsAndTypes('promotions'),
  failedJobs: generateActionsAndTypes('failedJobs'),
  fieldUpdateRequestItems: generateActionsAndTypes('fieldUpdateRequestItems'),
  fieldUpdateRequests: generateActionsAndTypes('fieldUpdateRequests'),
  fileActions: generateActionsAndTypes('fileActions'),
  fileLocations: generateActionsAndTypes('fileLocations'),
  files: generateActionsAndTypes('files'),
  genres: generateActionsAndTypes('genres'),
  handlers: generateActionsAndTypes('handlers'),
  holidays: generateActionsAndTypes('holidays'),
  indexConfiguratorActions: generateActionsAndTypes('indexConfiguratorActions'),
  indexConfigurators: generateActionsAndTypes('indexConfigurators'),
  masteringReferenceAudios: generateActionsAndTypes('masteringReferenceAudios'),
  masteringTracks: generateActionsAndTypes('masteringTracks'),
  instantMasteringOverview: generateActionsAndTypes('instantMasteringOverview'),
  instantMasteringPreviews: generateActionsAndTypes('instantMasteringPreviews'),
  instantMasteringTrackActions: generateActionsAndTypes(
    'instantMasteringTrackActions'
  ),
  instantMasteringTracks: generateActionsAndTypes('instantMasteringTracks'),
  instantMasterings: generateActionsAndTypes('instantMasterings'),
  invoiceDiscounts: generateActionsAndTypes('invoiceDiscounts'),
  invoiceItems: generateActionsAndTypes('invoiceItems'),
  invoicePayments: generateActionsAndTypes('invoicePayments'),
  invoiceTransactions: generateActionsAndTypes('invoiceTransactions'),
  invoices: generateActionsAndTypes('invoices'),
  invoicesOverview: generateActionsAndTypes('invoicesOverview'),
  isrcs: generateActionsAndTypes('isrcs'),
  labelActions: generateActionsAndTypes('labelActions'),
  labels: generateActionsAndTypes('labels'),
  languages: generateActionsAndTypes('languages'),
  logs: generateActionsAndTypes('logs'),
  newsExceprts: generateActionsAndTypes('newsExceprts'),
  notifications: generateActionsAndTypes('notifications'),
  orderItemActions: generateActionsAndTypes('orderItemActions'),
  orderItems: generateActionsAndTypes('orderItems'),
  orders: generateActionsAndTypes('orders'),
  ordersOverview: generateActionsAndTypes('ordersOverview'),
  paymentMethods: generateActionsAndTypes('paymentMethods'),
  payoutAddresses: generateActionsAndTypes('payoutAddresses'),
  payoutAddressesActions: generateActionsAndTypes('payoutAddressesActions'),
  payoutMethods: generateActionsAndTypes('payoutMethods'),
  payoutRequest: generateActionsAndTypes('payoutRequest'),
  payouts: generateActionsAndTypes('payouts'),
  pitching: generateActionsAndTypes('pitching'),
  platformTips: generateActionsAndTypes('platformTips'),
  pricePlans: generateActionsAndTypes('pricePlans'),
  priceRegions: generateActionsAndTypes('priceRegions'),
  prices: generateActionsAndTypes('prices'),
  pricesOverview: generateActionsAndTypes('pricesOverview'),
  products: generateActionsAndTypes('products'),
  productsOverview: generateActionsAndTypes('productsOverview'),
  publishers: generateActionsAndTypes('publishers'),
  revenueSplitTargets: generateActionsAndTypes('revenueSplitTargets'),
  referralRewards: generateActionsAndTypes('referralRewards'),
  releaseActions: generateActionsAndTypes('releaseActions'),
  releaseArtists: generateActionsAndTypes('releaseTracks'),
  releaseBarcodes: generateActionsAndTypes('releaseBarcodes'),
  releaseCovers: generateActionsAndTypes('releaseCovers'),
  releaseDeliveryBundles: generateActionsAndTypes('releaseDeliveryBundles'),
  releaseDeliveryStatus: generateActionsAndTypes('releaseDeliveryStatus'),
  releaseNames: generateActionsAndTypes('releaseNames'),
  releaseNotes: generateActionsAndTypes('releaseNotes'),
  releasePageActions: generateActionsAndTypes('releasePageActions'),
  releasePages: generateActionsAndTypes('releasePages'),
  releasePagesOverview: generateActionsAndTypes('releasePagesOverview'),
  releasePagesPressFiles: generateActionsAndTypes('releasePagesPressFiles'),
  releasePagesShops: generateActionsAndTypes('releasePagesShops'),
  releases: setFileActionsAndTypes(
    releasesCoverActions,
    releasesCoverActionTypes,
    'cover'
  )(generateActionsAndTypes('releases')),
  releaseTracks: generateActionsAndTypes('releaseTracks'),
  releaseTypes: generateActionsAndTypes('releaseTypes'),
  salesCustomerRevenues: generateActionsAndTypes('salesCustomerRevenues'),
  salesDistributionActions: generateActionsAndTypes('salesDistributionActions'),
  salesDistributions: generateActionsAndTypes('salesDistributions'),
  salesImportActions: generateActionsAndTypes('salesImportActions'),
  salesImports: setFileActionsAndTypes(
    salesImportsFileActions,
    salesImportsFileActionTypes
  )(generateActionsAndTypes('salesImports')),
  salesItemRevenues: generateActionsAndTypes('salesItemRevenues'),
  salesItems: generateActionsAndTypes('salesItems'),
  salesShops: generateActionsAndTypes('salesShops'),
  salesShopsGroups: generateActionsAndTypes('salesShopsGroups'),
  salesStatements: generateActionsAndTypes('salesStatements'),
  salesStatementsSplits: generateActionsAndTypes('salesStatementsSplits'),
  settings: generateActionsAndTypes('settings'),
  shops: generateActionsAndTypes('shops'),
  shortLinks: generateActionsAndTypes('shortLinks'),
  subscriptions: generateActionsAndTypes('subscriptions'),
  subscriptionsOverview: generateActionsAndTypes('subscriptionsOverview'),
  trackActions: generateActionsAndTypes('trackActions'),
  trackArtists: generateActionsAndTypes('trackArtists'),
  trackAudioFingerprintMatches: generateActionsAndTypes(
    'trackAudioFingerprintMatches'
  ),
  trackAudioFingerprints: generateActionsAndTypes('trackAudioFingerprints'),
  trackAudios: generateActionsAndTypes('trackAudios'),
  trackContributors: generateActionsAndTypes('trackContributors'),
  trackIsrcs: generateActionsAndTypes('trackIsrcs'),
  trackNames: generateActionsAndTypes('trackNames'),
  trackPublishers: generateActionsAndTypes('trackPublishers'),
  trackReleases: generateActionsAndTypes('trackReleases'),
  tracks: setFileActionsAndTypes(
    tracksAudioActions,
    tracksAudioActionTypes,
    'audio'
  )(generateActionsAndTypes('tracks')),
  transactions: generateActionsAndTypes('transactions'),
  translations: generateActionsAndTypes('translations'),
  trendFiles: generateActionsAndTypes('trendFiles'),
  trendItems: generateActionsAndTypes('trendItems'),
  trendsBasic: generateActionsAndTypes('trendsBasic'),
  trendsCountries: generateActionsAndTypes('trendsCountries'),
  trendsDevices: generateActionsAndTypes('trendsDevices'),
  trendsGenders: generateActionsAndTypes('trendsGenders'),
  trendsShops: generateActionsAndTypes('trendsShops'),
  trendsSources: generateActionsAndTypes('trendsSources'),
  trendsSummary: generateActionsAndTypes('trendsSummary'),
  trendsTopTracks: generateActionsAndTypes('trendsTopTracks'),
  userNotifications: generateActionsAndTypes('userNotifications'),
  users: generateActionsAndTypes('users'),
  userSocialAccounts: generateActionsAndTypes('userSocialAccounts'),
  videos: generateActionsAndTypes('videos'),
  voucherProducts: generateActionsAndTypes('voucherProducts'),
  voucherReleaseTypes: generateActionsAndTypes('voucherReleaseTypes'),
  vouchers: generateActionsAndTypes('vouchers'),
  voucherSubscriptions: generateActionsAndTypes('voucherSubscriptions'),
  voucherValues: generateActionsAndTypes('voucherValues'),
};
export default entities;
