import React, { useCallback } from 'react';
import { IconButton } from 'imdui';
import { EventTypes } from 'redux-segment';
import { usePlayer } from '../PlayerProvider';
import { PauseIcon, PlayIcon } from '@imus/base-ui';

const PlayPauseButton = ({
  style,
  className,
  disabled: propDisabled = false,
  trackToBePlayed,
  globalPlay,
  inline,
}: {
  globalPlay?: boolean;
  inline?: boolean;
  trackToBePlayed?: number | string;
  fileUrlToBePlayed?: number | string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const { fileUrl, audioFile, play, pause, playState, trackId } =
    usePlayer(trackToBePlayed);
  const disabled = propDisabled || !fileUrl;

  const isPlaying = globalPlay || fileUrl === audioFile;

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (fileUrl === 'pending') {
        return;
      }
      if (playState !== 'play' || !isPlaying) {
        play(trackToBePlayed, 0, fileUrl, {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: 'FT Play Audio Track',
              properties: {
                trackId,
              },
            },
          },
        });
        return;
      }
      pause();
    },
    [trackToBePlayed, isPlaying, playState, play, fileUrl]
  );

  return (
    <IconButton
      inline={inline}
      style={style}
      className={className}
      testId={`PlayPauseButton-${disabled ? 'disabled' : 'enabled'}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {playState === 'play' && isPlaying ? <PauseIcon /> : <PlayIcon />}
    </IconButton>
  );
};

export default PlayPauseButton;
